<template>
    <div>
        <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app class="nav-bar" style="" dark>
            <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer" /> -->
            <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
                <span class="hidden-sm-and-down">Library</span>
            </v-toolbar-title><v-spacer />
            <span v-if="org != null">
                <center>
                    <h3>
                        <v-chip class="ma-2 pa-4" color="orange" text-color="white">
                            <v-avatar left>
                                <v-icon size="22">home_work</v-icon>
                            </v-avatar>
                            {{ org }}
                        </v-chip>
                    </h3>
                </center>
            </span>
            <v-spacer />
            <!-- <v-btn icon>
           <v-avatar >
          <v-icon>mdi-bell</v-icon>
          </v-avatar> 
        </v-btn>-->
            <div style="width:10px"> </div>
            <div class="text-center">
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x
                    transition="scroll-y-transition">
                    <template v-slot:activator="{ on }">
                        <span v-if="!mobile">Hello, {{ name }}</span>
                        <v-btn icon v-on="on">
                            <span v-if="profile_image_path">
                                <v-avatar>
                                    <v-img :src="awspath + profile_image_path + profile_image_name"></v-img>
                                </v-avatar>
                            </span>
                            <span v-else>
                                <v-avatar color="white">
                                    <span class="indigo--text headline">{{ initials }}</span>
                                </v-avatar>
                            </span>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <span v-if="profile_image_path">
                                        <v-avatar>
                                            <v-img :src="awspath + profile_image_path + profile_image_name"></v-img>
                                        </v-avatar>
                                    </span>
                                    <span v-else>
                                        <v-avatar color="#4A148C">
                                            <span class="white--text headline">{{ initials }}</span>
                                        </v-avatar>
                                    </span>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Hello,<br /><span style="padding-bottom:15px">{{ name
                                    }}</span></v-list-item-title>
                                    <!-- <v-list-item-subtitle><span style="padding-bottom:5px"></span>{{uid}}</v-list-item-subtitle> -->
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-list shaped>
                            <v-list-item-group color="primary">
                                <router-link to="/user-profile" class="sidebar-link">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon size="30" color="warning">mdi-account-circle-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>User Profile</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </router-link>

                                <router-link to="/update-password" class="sidebar-link">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon size="25" color="warning">mdi-account-key</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Change Password</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </router-link>

                            </v-list-item-group>
                        </v-list>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn class="ma-1" outlined small fab color="error" @click="closeMenuAndLogout()"
                                title="logout">
                                <v-icon>mdi-location-exit</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </div>
        </v-app-bar>
        <!-- <v-navigation-drawer :clipped="$vuetify.breakpoint.lgAndUp" app v-model="drawer">
            <v-list>

                <v-list-group no-action v-for='navLink in navLinks' :key="navLink.text">
                    <v-list-item style="padding:0px; marign:0px;" slot='activator' :to="navLink.route">
                        <v-list-item-icon>
                            <v-icon>{{ navLink.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title style="margin-left:-40px !important">{{ navLink.text }}</v-list-item-title>
                    </v-list-item>
                    <v-divider style="margin:0px"></v-divider>
                    <v-list-item style="padding-left:20px; " v-for='sub in navLink.subLinks' :key="sub.text">

                        <router-link class="sidebar-link" :to="{ name: sub.route }">
                            <div class="d-flex">

                                <v-icon class="instagram pr-2">{{ sub.icon }}</v-icon>

                                <v-list-item-title>{{ sub.text }}</v-list-item-title>
                            </div>
                        </router-link>

                    </v-list-item>
                    <v-divider style="margin:0px"></v-divider>
                </v-list-group>
            </v-list>
        </v-navigation-drawer> -->


    </div>
</template>
    
<script>
import axios from 'axios'

export default {

    data: () => ({
        initials: '',
        menu: false,
        org: null,
        username: '',
        uid: '',
        data: {},
        profile_image_name: '',
        profile_image_path: '',
        mobile: false,
        name: null,
        drawer: true,
        items: [],
        navLinks: [],
        sorting: -1,
    }),
    methods: {
        // toggleDrawer() {
        //     this.$store.state.drawer = !this.$store.state.drawer;
        // },
        closeMenuAndLogout() {
            if (this.uid == "superadmin@easypariksha.com") {
                this.menu = false;
                localStorage.clear();
                this.$router.push('/eps-admin');
            } else {
                this.menu = false;
                localStorage.clear();
                this.$router.push('/');
            }

        },
        imageReplacement(uername) {
            //var names = this.uername.split('');
            //console.log("names "+names)
            this.initials = uername[0].charAt(0).toUpperCase()
            // window.alert(initials)
        }
    },
    mounted() {

        // axios
        //     .get("/Home/getRoleWithRoleLink")
        //     .then(res => {
        //         // this.items = res.data.links;
        //         this.navLinks = res.data.navLinks;

        //     })
        //     .catch(error => {
        //         window.console.log(error);
        //         this.$router.push("/");
        //     });
        let devicewidth = window.innerWidth
        if (devicewidth < 700) {
            this.mobile = true
        }

        this.$store.commit('setquick_links', true)
        this.awspath = localStorage.getItem("awspath");
        this.uid = localStorage.getItem('EPS-uid');
        axios.get("home/getusername")
            .then(res => {
                //window.console.log("res"+res.data)
                if (res.data.msg == "200") {
                    this.data = res.data
                    this.org = res.data.org;
                    this.uername = res.data.name
                    this.name = res.data.name
                    //window.alert(this.uername)   
                    this.profile_image_name = this.data.profile_image_name
                    this.profile_image_path = this.data.profile_image_path
                    this.imageReplacement(this.uername)

                } else {
                    console.log('in else')
                }
            })
            .catch(error => {
                window.console.log(error)
            })
    },
};
</script>
    
<style scoped>
.sidebar-link {
    text-decoration: none;
}

.sidebar-link-item:hover {
    background-color: rgb(181, 186, 190) !important;
}

.hidden-sm-and-down {
    font-weight: 500;
}

.nav-bar {
    /* background: cadetblue !important; */
    background: #000000c7;
}

.sidebar-link {
    text-decoration: none;
}

.sidebar-link-item:hover {
    background-color: rgb(202, 206, 209) !important;
}

.title-role {
    margin-left: 35px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    line-height: 1.0;
    font-weight: 500;
}

.instagram {
    background: -webkit-radial-gradient(33% 100% circle, #30CFD0 4%, #30CFD0 22%, #30CFD0 22%, #330867 85%, #330867);
    background: radial-gradient(circle at 33% 100%, #30CFD0 4%, #30CFD0 22%, #30CFD0 22%, #330867 85%, #330867);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>