<template>
<div></div>
<!-- <div class="overflow-hidden">
    <div class="text-right mb-2">
        <v-avatar color="orange" v-if="!firefoxAgent" class="ma-2 white--text" size="67" outlined>
            <Speed />
        </v-avatar>
    </div>

</div> -->
</template>

<script>
// import Speed from "./Speed";
export default {
    components: {
        // Speed,
    },
    mounted(){
         let userAgentString =  navigator.userAgent;
          this.firefoxAgent = userAgentString.indexOf("Firefox") > -1; 
        //  alert(firefoxAgent)
    },
    data() {
        return {
            activeBtn: 1,
            showNav: false,
            firefoxAgent:false
        }
    },
}
</script>

<style scoped>
.overflow-hidden {
    top: 60px;
    right: 0px;
    position: fixed !important;
}
</style>
