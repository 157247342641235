<template>
  <v-app id="inspire">
    
   

    <Header :drawer="$store.state.drawer"></Header>
 
    <v-content class="mx-5">
      <v-container fluid> 
          
      </v-container>
      <router-view></router-view> 
      <!-- <div>Quick-Links RD
        <router-link :to="{name:'employee-type'}">click</router-link>
      </div> -->
    </v-content>
     <Footer :drawer="$store.state.drawer"></Footer>
  </v-app>
</template>

<script>
import axios from 'axios'
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
export default {
  //props: ['para_role_type'],
  props: {
    role:String,
  },
  data() {
      return {
          // rt:this.$route.query.roletype
          data:{},
          parentUrl:'',
          links:null,
          rolename:null
          }
  },
  components: {
     Header:Header,
     Footer:Footer,
  },
  methods:{
    back(){
      this.$router.push('/home');
    }
  },
  watch:{
    
  },
  mounted(){
    this.rolename = this.$route.params.role

    const paradata={
      role:this.rolename,
    }
    axios.post("/home/getlinks",paradata)
    .then(res =>{
      //window.console.log("res"+res.data)
      if(res.data.msg=="200"){
          console.log(res.data)
          this.links = res.data.links
          this.parentUrl = this.$route.path
      }
      else{
        console.log('empty')
      }
    })
    .catch(error => {
        window.console.log(error)
    })
  } 
}
</script>


<style scoped>
.sidebar-link {
  text-decoration: none;
}
.sidebar-link-item:hover {
  background-color: rgb(181, 186, 190) !important;
}
@media print {
  .print-push-left{
    margin-left: -256px;
    display: none !important;
  }
}
/* .instagram {
        text-transform: uppercase;
      background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  } */
  .instagram {
    background: -webkit-radial-gradient(33% 100% circle, #30CFD0 4%, #30CFD0 22%, #30CFD0 22%, #330867 85%, #330867);
    background: radial-gradient(circle at 33% 100%, #30CFD0 4%, #30CFD0 22%, #30CFD0 22%, #330867 85%, #330867);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>